// require('dotenv').config();
import React from 'react';
import { LicenseManager } from 'ag-grid-enterprise';

import ToasterComponent from './Components/UI/Toaster';
import Routes from './Routes';
import './Assets/css/bootstrap.min.css';
import './Assets/css/icons.css';
import './Assets/css/common.css';
import './Assets/css/dashboard.css';
import './Assets/css/login.css';
import './Assets/css/registration_page.css';
import './Assets/css/forgot_password.css';
import './Assets/css/user_management.css';
import './Assets/css/sidepanel.css';
import StoreProvider from './Store/StoreProvider';

LicenseManager.setLicenseKey('Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-076341}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Reg_X_Innovations}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{reg-x}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{reg-x}_need_to_be_licensed___{reg-x}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{4_February_2026}____[v3]_[0102]_MTc3MDE2MzIwMDAwMA==5750d9a51f168fe3e237b2b0864eb237');

function App() {
  return (
    <div>
      <StoreProvider>
        <Routes />
        <ToasterComponent />
      </StoreProvider>
    </div>
  );
}

export default App;
